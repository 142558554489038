import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '../../ui/common';
import {
  Section,
  TextContainer,
  BigIntroTitle,
  IntroDescription,
  IntroSubTitle,
} from './styled';

function Intro({ title, subTitle, description }) {
  return (
    <Section>
      <Container>
        <TextContainer>
          <BigIntroTitle>{title}</BigIntroTitle>
          <IntroSubTitle>{subTitle}</IntroSubTitle>
          <IntroDescription>{description}</IntroDescription>
        </TextContainer>
      </Container>
    </Section>
  );
}

export default Intro;

Intro.propTypes = {
  title: PropTypes.node,
  subTitle: PropTypes.node,
  description: PropTypes.node,
};

Intro.defaultProps = {
  title: '',
  subTitle: '',
  description: '',
};
