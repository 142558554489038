import styled from 'styled-components';
import media from '../../../../ui/media';
import { colors } from '../../../../ui/common';

export const ListsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-flow: row nowrap;
  padding-top: 80px;
  padding-bottom: 70px;
  margin: 0 -15px;
  overflow-x: scroll;
  ${media.phone`
    padding-top: 100px;
    margin: 0 auto;
    padding-bottom: 60px;
  `};
  @media (min-width: 768px) {
    overflow-x: hidden;
  }
`;

export const List = styled.div`
  background: ${(props) => (props.coolPrivilege ? '#F7FBFB' : '#FFFFFF')};
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  padding: 20px 15px;
  margin: 0 15px;
  max-width: 539px;
  min-width: 269px;
  box-sizing: border-box;
  flex: 0 1 269px;
  ${media.phone`
    margin: 0 auto;
  `};
  ${media.medium`
    padding: 32px 30px;
    flex: 0 1 47%;
  `};
  ${media.laptop`
    padding: 32px 64px;
    flex: 0 1 47%;
  `};
`;

export const ListTitle = styled.h3`
  font-weight: 500;
  font-size: 21px;
  line-height: 150%;
  color: ${colors.color05};
  text-align: center;
  border-bottom: 1px solid rgba(3, 102, 99, 0.1);
  margin: 0 0 40px;
  padding-bottom: 20px;
  span {
    color: #fdc1aa;
  }
  ${media.medium`
    font-size: 24px;
  `};
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  &:last-child {
    align-items: flex-start;
    margin-top: 25px;
  }

  img {
    max-width: 30px;
    margin-right: 20px;
    ${media.tablet`
      margin-right: 30px;
    `}
  }
  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 162.4%;
    color: ${colors.color05};
    opacity: 0.9;
    margin: 0;
  }
`;

export const AfterWords = styled.p`
  text-align: center;
  color: ${colors.color05};
  padding-top: 0;
  padding-bottom: 100px;
  opacity: 0.9;
  margin: 0 auto;
  font-weight: 300;
  font-size: 12px;
  line-height: 180%;
  white-space: pre-wrap;
  ${media.laptop`
    width: 70%;
    padding-bottom: 120px;
  `}
`;
