import React from 'react';
import { FormattedMessage } from 'react-intl';
import FAQ from '../components/Products/FAQ';
import Footer from '../components/Footer';
import NavBar from '../components/Navbar';
import CoolPrivilegeCompare from '../components/Products/CoolPrivilege/Compare/index';
import Intro from '../components/Products/CoolPrivilege/Intro/index';

export default function CoolPrivilege() {
  return (
    <>
      <NavBar />
      <Intro
        title={
          <FormattedMessage
            id="cool_privilege.title"
            values={{
              // eslint-disable-next-line react/no-unstable-nested-components
              span: (chunks) => <span>{chunks}</span>,
            }}
          />
        }
        subTitle={<FormattedMessage id="cool_privilege.subtitle" />}
        description={<FormattedMessage id="cool_privilege.description" />}
      />
      <CoolPrivilegeCompare />
      <FAQ />
      <Footer />
    </>
  );
}
