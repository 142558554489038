import valid1 from '../../../../images/product-page/coolprivilege/icon-valid1.svg';
import valid2 from '../../../../images/product-page/coolprivilege/icon-valid2.svg';
import list from '../../../../images/product-page/coolprivilege/icon-list.svg';
import settings from '../../../../images/product-page/coolprivilege/icon-settings.svg';
import r20 from '../../../../images/product-page/coolprivilege/icon-r-20.svg';
import r7 from '../../../../images/product-page/coolprivilege/icon-r-7.svg';
import world from '../../../../images/product-page/coolprivilege/icon-world.svg';
import shield from '../../../../images/product-page/coolprivilege/icon-shield.svg';
import thumb from '../../../../images/product-page/coolprivilege/icon-thumb.svg';

export const basicWarrantyList = [
  {
    title: 'cool_privilege.compare.basic.valid',
    img: valid1,
  },
  {
    title: 'cool_privilege.compare.basic.customer_list',
    img: list,
  },
  {
    title: 'cool_privilege.compare.basic.customer_remote',
    img: settings,
  },
  {
    title: 'cool_privilege.compare.basic.replacement',
    img: r20,
  },
  {
    title: 'cool_privilege.compare.worldwide',
    img: world,
  },
  {
    title: 'cool_privilege.compare.basic.warranty',
    img: shield,
  },
];

export const coolPrivilegeList = [
  {
    title: 'cool_privilege.compare.cool.valid',
    img: valid2,
  },
  {
    title: 'cool_privilege.compare.cool.customer_list',
    img: list,
  },
  {
    title: 'cool_privilege.compare.cool.customer_remote',
    img: settings,
  },
  {
    title: 'cool_privilege.compare.cool.replacement',
    img: r7,
  },
  {
    title: 'cool_privilege.compare.worldwide',
    img: world,
  },
  {
    title: 'cool_privilege.compare.cool.coverage',
    img: thumb,
  },
  {
    title: 'cool_privilege.compare.cool.warranty',
    img: shield,
  },
];
