import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container } from '../../ui/common';
import { Section } from '../../Compare/styled';
import { basicWarrantyList, coolPrivilegeList } from './data';
import { ListsContainer, List, ListTitle, Row, AfterWords } from './styled';

function Compare() {
  const basicList = basicWarrantyList.map((item) => (
    <Row key={Math.random()}>
      <img src={item.img} alt={item.title.slice(0, 20)} />
      <p>
        <FormattedMessage id={item.title} />
      </p>
    </Row>
  ));

  const coolPrivilegesList = coolPrivilegeList.map((item) => (
    <Row key={Math.random()}>
      <img src={item.img} alt={item.title.slice(0, 20)} />
      <p>
        <FormattedMessage id={item.title} />
      </p>
    </Row>
  ));

  return (
    <Section>
      <Container>
        <ListsContainer>
          <List>
            <ListTitle>
              <FormattedMessage
                id="cool_privilege.basic.title"
                values={{
                  // eslint-disable-next-line react/no-unstable-nested-components
                  span: (chunks) => <span>{chunks}</span>,
                }}
              />
            </ListTitle>
            {basicList}
          </List>
          <List coolPrivilege>
            <ListTitle>
              <FormattedMessage
                id="cool_privilege.title"
                values={{
                  // eslint-disable-next-line react/no-unstable-nested-components
                  span: (chunks) => <span>{chunks}</span>,
                }}
              />
            </ListTitle>
            {coolPrivilegesList}
          </List>
        </ListsContainer>
        <AfterWords>
          <FormattedMessage id="cool_privilege.compare.footer" />
        </AfterWords>
      </Container>
    </Section>
  );
}
export default Compare;
