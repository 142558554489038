import styled from 'styled-components';
import { media, sizes } from '../../ui/common';
import mobileBackground from '../../../../images/product-page/coolprivilege/cool_privelege_first_screen_bg_mobile.jpg';
import tabletBackground from '../../../../images/product-page/coolprivilege/cool_privelege_first_screen_bg_tablet.jpg';
import desktopBackground from '../../../../images/product-page/coolprivilege/cool_privelege_first_screen_bg.jpg';

export const Section = styled.section`
  background-image: url(${mobileBackground});
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  padding-top: 150px;
  padding-bottom: 50px;
  @media (min-width: 375px) {
    padding-top: 200px;
    padding-bottom: 80px;
  }
  @media (min-width: ${sizes.phone}px) {
    background-image: url(${tabletBackground});
    padding-top: 230px;
    padding-bottom: 140px;
  }
  @media (min-width: ${sizes.laptop}px) {
    background-image: url(${desktopBackground});
    padding-top: 260px;
    padding-bottom: 170px;
  }
`;

export const TextContainer = styled.div`
  box-sizing: border-box;
  ${media.phone`
    position: relative;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 480px;
  `}
  ${media.medium`
    max-width: 60%;
  `}
  ${media.laptop`
  `};
`;

export const BigIntroTitle = styled.h1`
  font-weight: 500;
  font-size: 32px;
  line-height: 122.9%;
  color: #fff;
  margin-top: 0;
  margin-bottom: 15px;
  span {
    color: #fdc1aa;
  }
  ${media.tablet`
    font-size: 52px;
  `};
`;

export const IntroSubTitle = styled.h2`
  font-weight: 500;
  font-size: 23px;
  line-height: 130%;
  color: #fff;
  margin-top: 0;
  margin-bottom: 23px;
  ${media.tablet`
    font-size: 30px;
  `};
`;

export const IntroDescription = styled.p`
  font-weight: normal;
  font-size: 18px;
  line-height: 170%;
  color: #fff;
  margin-top: 0;
`;
